import axios from "axios";
import React, { useState } from "react";
import { v4 as uuidv4 } from 'uuid';

export default function AddNewLocation({
  AreaID,
  setOpenModalAddLokasi,
  handleRefreshDataArea
}) {

  const [nameLocation, setNameLocation] = useState("");
  const [latitude, setLatitude] = useState(1);
  const [longitude, setLongitude] = useState(0);
  const [code, setCode] = useState("")

  const handleAddNewLocation = async () => {
    // console.log(
    //   {
    //     _id: uuidv4(),
    //     name: nameLocation,
    //     coordinates: [longitude, latitude]
    //   }
    // );
    await axios
      .post(
        process.env.REACT_APP_BASE_URL + `area/` + AreaID + "/locations",
        {
          _id: uuidv4(),
          name: nameLocation,
          code: code,
          coordinates: [longitude, latitude]
        },
        {
          headers: {
            Authorization: process.env.REACT_APP_TOKEN,
          },
        }
      )
      .then(function (result) {
        // console.log(result);
        setOpenModalAddLokasi(false)
        handleRefreshDataArea()
      })
      .catch(function (error) {
        // setMessageAlert("Please contact your Administrator");
        // setTypeAlert("danger");
        // setAlertVisible(true);
        // console.log(error);
      });
  };

  return (
    <div className="m-3">
      <div className="bg-white w-full dark:bg-gray-700">
        <form class="max-w-sm mx-auto">
          <div class="mb-5">
            <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Location Name</label>
            <input onChange={(e)=> setNameLocation(e.target.value)} type="text" id="name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="assembly point" required />
          </div>
          <div class="mb-5">
            <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Code</label>
            <input onChange={(e)=> setCode(e.target.value)} type="text" id="name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="*Optional to join System Mevent" />
          </div>
          <div class="mb-5 flex gap-2">
            <div>
              <label for="latitude" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Latitude</label>
              <input onChange={(e)=> setLatitude(e.target.value)} type="number" id="latitude" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
            </div>
            <div>
              <label for="latitude" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Longitude</label>
              <input onChange={(e)=> setLongitude(e.target.value)} type="number" id="latitude" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
            </div>
          </div>
          <button onClick={handleAddNewLocation} class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Submit</button>
        </form>

      </div>
    </div>
  );
}