import React from "react";
import QRCode from "react-qr-code";

export default function ListQRArea({
  room,
  nameArea,
  lastAreaSelect,
  dataJson,
  setOpenModalChangeDataLocation,
}) {

  const onImageCownload = (nameArea) => {
    const svg = document.getElementById("QRCode");

    // Check if the SVG element is found
    if (!svg) {
      console.error("SVG element with ID 'QRCode' not found");
      return;
    }

    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();

    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL("image/png");
      const downloadLink = document.createElement("a");
      downloadLink.download = "Area " + nameArea;
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    };

    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
  };
  // console.log("ini", dataJson.locations)
  return (
    <div className="m-3">
      <div className="bg-white w-full dark:rounded-lg dark:shadow-lg">
        <div class="grid grid-cols-2 md:grid-cols-3 gap-4 max-h-72 overflow-auto scroll-auto">
          {dataJson.locations.map((data) => (
            <div key={data._id} className="border-b px-2">
              <div
                onClick={() => onImageCownload(data.name)}
                className="flex justify-center p-2"
              >
                <QRCode
                  id="QRCode"
                  size={600}
                  style={{ height: "auto", maxWidth: "100%", width: "100" }}
                  value={data._id}
                  viewBox={`0 0 600 600`}
                />
              </div>
              <div className="font-semibold text-xs text-center">{data.name}</div>
            </div>

          ))}
        </div>
      </div>
    </div>
  );
}