import axios from "axios";
import React from "react";

export default function LayerTools({
  dataUpdateLayer,
  nameArea,
  nameLokasi,
  room,
  handleCloseLayerUpdate,
  getAreaBounds,
}) {
  const handleUpdate = async () => {
    // console.log(room);
    // console.log(dataUpdateLayer)
    await axios
      .put(
        process.env.REACT_APP_BASE_URL + `area/locations/` + room ,
        {
          bounds: dataUpdateLayer,
        },
        {
          headers: {
            Authorization: process.env.REACT_APP_TOKEN,
          },
        }
      )
      .then(function (result) {
        getAreaBounds(result.data.name, result.data._id);
        // console.log("hasil update",result);
        handleCloseLayerUpdate();
      })
      .catch(function (error) {
        // setMessageAlert("Please contact your Administrator");
        // setTypeAlert("danger");
        // setAlertVisible(true);
        // console.log(error);
      });
  };
  return (
    <div>
      <div class="absolute bottom-32 right-10 flex items-center w-full max-w-xs p-4 mb-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800">
        <div class="flex">
          <div class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-blue-500 bg-blue-100 rounded-lg dark:text-blue-300 dark:bg-blue-900">
            <svg
              class="w-4 h-4"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 18 20"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M16 1v5h-5M2 19v-5h5m10-4a8 8 0 0 1-14.947 3.97M1 10a8 8 0 0 1 14.947-3.97"
              />
            </svg>
            <span class="sr-only">Refresh icon</span>
          </div>
          <div class="ml-3 text-sm font-normal">
            <span class="mb-1 text-sm font-semibold text-gray-900 dark:text-white">
              Update Layer is available
            </span>
            <div class="mb-2 text-sm font-normal">
              A new Layer on {nameLokasi} {nameArea} is available for update.
            </div>
            <div class="grid grid-cols-2 gap-2">
              <div>
                <div
                  onClick={() => handleUpdate()}
                  class="cursor-pointer inline-flex justify-center w-full px-2 py-1.5 text-xs font-medium text-center text-white bg-blue-600 rounded-lg hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-500 dark:hover:bg-blue-600 dark:focus:ring-blue-800"
                >
                  Update
                </div>
              </div>
              <div>
                <div
                  onClick={() => handleCloseLayerUpdate()}
                  class="cursor-pointer inline-flex justify-center w-full px-2 py-1.5 text-xs font-medium text-center text-gray-900 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 dark:bg-gray-600 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-700 dark:focus:ring-gray-700"
                >
                  Not now
                </div>
              </div>
            </div>
          </div>
          <button
            type="button"
            onClick={() => handleCloseLayerUpdate()}
            class="ml-auto -mx-1.5 -my-1.5 bg-white items-center justify-center flex-shrink-0 text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
            aria-label="Close"
          >
            <span class="sr-only">Close</span>
            <svg
              class="w-3 h-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 14"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
}
