import axios from "axios";
import { setCookie } from "nookies";
import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-number-input";
import logo from "./logoOG.png";
import "react-phone-number-input/style.css";
import Alert from "../components/Alert/Alert";
export default function Signin({ handleAuth }) {
  const [phoneNumber, setPhoneNumber] = useState(0);
  const [validNumber, setValidNumber] = useState(false);
  const [alertVisible, setAlertVisible] = useState(false);
  const [messageAlert, setMessageAlert] = useState(undefined);
  const [typeAlert, setTypeAlert] = useState(undefined);
  const [idAdmin, setIdAdmin] = useState(undefined);
  const [usernameAdmin, setUsernameAdmin] = useState(undefined);
  const [role, setRole] = useState("admin");
  const [OTP, setOtp] = useState("");
  const [loading, setLoading] = useState(false);

  const handleValidatePhoneNumber = (e) => {
    setPhoneNumber(e);
  };

  const handleGETOTP = (validPhoneNumber) => {
    axios
      .post(
        process.env.REACT_APP_BASE_URL + `auth/getotp`,
        {
          nohp: validPhoneNumber,
        },
        {
          headers: {
            Authorization: process.env.REACT_APP_TOKEN,
          },
        }
      )
      .then(function (response) {
        // handle success
        setMessageAlert(response.data.Data.data.login);
        setTypeAlert("success");
        setAlertVisible(true);
        setValidNumber(true);
      })
      .catch(function (error) {
        // handle error
        setMessageAlert("Please contact your Administrator");
        setTypeAlert("danger");
        setAlertVisible(true);
        // console.log(error.response.data.message);
      });
  };

  const handleVerifyOTP = () => {
    axios
      .post(
        process.env.REACT_APP_BASE_URL + `auth/verifyotp`,
        {
          username: phoneNumber.split("+")[1],
          otp: OTP,
        },
        {
          headers: {
            Authorization: process.env.REACT_APP_TOKEN,
          },
        }
      )
      .then(function (response) {
        // handle
        if (response.data.Data.data.login !== "ok") {
          setMessageAlert(response.data.Data.data.error);
          setTypeAlert("info");
          setAlertVisible(true);
        } else {
          // console.log(idAdmin, usernameAdmin, );
          setUserData(
            idAdmin,
            usernameAdmin,
            response.data.Data.data.area,
            phoneNumber.split("+")[1],
            role
          );
          handleAuth();
        }
        // AddNewUser(JSON.parse(response.data.data.data));
      })
      .catch(function (error) {
        setMessageAlert("Invalid OTP");
        setTypeAlert("danger");
        setAlertVisible(true);
        // handle error
        // console.log(error);
      });
  };

  const handleCekValidateAdmin = async () => {
    const phoneRegex = /^(?:\+62|62|0)8[1-9][0-9]{6,11}$/;

    if (phoneNumber && phoneNumber !== "0" && phoneRegex.test(phoneNumber)) {
      setLoading(true);

      await axios
        .get(
          process.env.REACT_APP_BASE_URL +
            `admin/filter?nohp=${phoneNumber.split("+")[1]}`,
          {
            headers: {
              Authorization: process.env.REACT_APP_TOKEN,
            },
          }
        )
        .then(function (result) {
          setIdAdmin(result.data._id);
          setUsernameAdmin(result.data.name);
          setRole(result.data.role);
          handleGETOTP(result.data.nohp);
          setLoading(false)
        })
        .catch(function (error) {
          setMessageAlert("Please contact your Administrator");
          setTypeAlert("danger");
          setAlertVisible(true);
          // console.log(error);
        });
    } else {
      setMessageAlert("Please insert your valid number");
      setTypeAlert("danger");
      setAlertVisible(true);
    }
  };

  const setUserData = (id, username, area, nohp, role) => {
    // Set the cookie with a 1-week expiration (7 days * 24 hours * 60 minutes * 60 seconds)
    const oneWeekInSeconds = 7 * 24 * 60 * 60;

    setCookie(null, "userId", id, {
      maxAge: oneWeekInSeconds,
      path: "/",
    });

    setCookie(null, "username", username, {
      maxAge: oneWeekInSeconds,
      path: "/",
    });

    setCookie(null, "area", area, {
      maxAge: oneWeekInSeconds,
      path: "/",
    });

    setCookie(null, "nohp", nohp, {
      maxAge: oneWeekInSeconds,
      path: "/",
    });

    setCookie(null, "role", role, {
      maxAge: oneWeekInSeconds,
      path: "/",
    });
  };

  useEffect(() => {
    if (alertVisible === true) {
      setTimeout(() => {
        setAlertVisible(false);
      }, 5000);
    }
  }, [alertVisible]);

  return (
    <div class="antialiased bg-gradient-to-br from-orange-100 to-white">
      <div
        className={`${
          alertVisible ? "scale-100 opacity-100" : "scale-90 opacity-0"
        }`}
      >
        <Alert data={messageAlert} type={typeAlert} />
      </div>
      <div class="container px-6 mx-auto">
        <div class="flex flex-col text-center md:text-left md:flex-row h-screen justify-evenly md:items-center">
          <div class="inline-flex w-full">
            <img src={logo} width={70} height={"100%"} />
            <div>
              <h1 class="text-5xl text-orange-600 font-bold">
                I-PIT Dashboard
              </h1>
              <p class="w-auto mx-auto md:mx-0 text-gray-500">
                Integrated Personil Position Tracker
              </p>
            </div>
          </div>
          <div class="w-auto md:w-full lg:w-5/12 mx-auto md:mx-0">
            <div class="bg-gradient-to-br from-gray-100 p-10 flex flex-col w-full shadow-xl rounded-xl">
              <h2 class="text-2xl font-bold text-gray-800 text-left mb-5">
                Sign in
              </h2>
              <div class="w-full">
                {validNumber === false ? (
                  <>
                    <div class="w-full my-5">
                      <PhoneInput
                        defaultCountry="ID"
                        placeholder="Enter phone number"
                        value={phoneNumber}
                        onChange={(e) => handleValidatePhoneNumber(e)}
                      />
                    </div>
                    <div id="button" class="flex flex-col w-full my-5">
                      {loading === true ? (
                        <>
                          <button
                            type="button"
                            class="w-full py-4 bg-orange-600 rounded-lg text-orange-100 hover:bg-orange-700"
                          >
                            <div class="flex flex-row items-center justify-center">
                              <div class="mr-2">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  fill="currentColor"
                                  class="w-6 h-6 animate-spin"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    d="M4.755 10.059a7.5 7.5 0 0 1 12.548-3.364l1.903 1.903h-3.183a.75.75 0 1 0 0 1.5h4.992a.75.75 0 0 0 .75-.75V4.356a.75.75 0 0 0-1.5 0v3.18l-1.9-1.9A9 9 0 0 0 3.306 9.67a.75.75 0 1 0 1.45.388Zm15.408 3.352a.75.75 0 0 0-.919.53 7.5 7.5 0 0 1-12.548 3.364l-1.902-1.903h3.183a.75.75 0 0 0 0-1.5H2.984a.75.75 0 0 0-.75.75v4.992a.75.75 0 0 0 1.5 0v-3.18l1.9 1.9a9 9 0 0 0 15.059-4.035.75.75 0 0 0-.53-.918Z"
                                    clip-rule="evenodd"
                                  />
                                </svg>
                              </div>
                              <div class="font-bold">Processing...</div>
                            </div>
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            onClick={handleCekValidateAdmin}
                            type="button"
                            class="w-full py-4 bg-orange-600 rounded-lg text-orange-100 hover:bg-orange-700"
                          >
                            <div class="flex flex-row items-center justify-center">
                              <div class="mr-2">
                                <svg
                                  class="w-6 h-6"
                                  fill="none"
                                  stroke="currentColor"
                                  viewBox="0 0 24 24"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1"
                                  ></path>
                                </svg>
                              </div>
                              <div class="font-bold">Sign in</div>
                            </div>
                          </button>
                        </>
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <div id="input" class="flex flex-col w-full my-5">
                      <label for="password" class="text-gray-500 mb-2">
                        OTP
                      </label>
                      <input
                        onChange={(e) => setOtp(e.target.value)}
                        type="password"
                        id="password"
                        placeholder="Please insert your OTP"
                        class="appearance-none border-2 border-gray-100 rounded-lg px-4 py-3 placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-orange-600 focus:shadow-lg"
                      />
                    </div>
                    <div id="button" class="flex flex-col w-full my-5">
                      <button
                        onClick={handleVerifyOTP}
                        type="button"
                        class="w-full py-4 bg-orange-600 rounded-lg text-orange-100 hover:bg-orange-700"
                      >
                        <div class="flex flex-row items-center justify-center">
                          <div class="mr-2">
                            <svg
                              class="w-6 h-6"
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1"
                              ></path>
                            </svg>
                          </div>
                          <div class="font-bold">Verify OTP</div>
                        </div>
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
