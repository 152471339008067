import MapboxDraw from "@mapbox/mapbox-gl-draw";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import axios from "axios";
import xlsx from "json-as-xlsx";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import MapboxWorker from "mapbox-gl/dist/mapbox-gl-csp-worker";
import React, { useEffect, useRef, useState } from "react";
import "react-calendar/dist/Calendar.css";
import io from "socket.io-client";
import BroadcastTabs from "./components/BroadcastTabs/BroadcastTabs";
import InfoTabs from "./components/InfoTabs/InfoTabs";
import ListAdmin from "./components/ListAdmin/ListAdmin";
import {
  BroadcastIcon,
  ExportFileIcon,
  GroupAdminIcon,
  LocationIcon,
  LogOutIcon,
  QRIcon,
} from "./components/SVG/index";
import { parseCookies } from "nookies";
import Alert from "./components/Alert/Alert";
import LayerTools from "./components/LayerTools/LayerTools";
import ListLocation from "./components/ListLocation/ListLocation";
import ListQRArea from "./components/ListQRArea/ListQRArea";
import AddNewLocation from "./components/AddNewLocation/AddNewLocation";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import ListUserLogs from "./components/ListUserLogs/ListUserLogs";
import ExportTrack from "./components/ExportTrack/ExportTrack";
const socket = io.connect(process.env.REACT_APP_SOCKET);

const Map = ({
  handleLogOut,
  setPages,
  setNameUserTrack,
  setDateUserTrack,
  setRoomUserTrack,
}) => {
  mapboxgl.MapboxWorker = MapboxWorker;
  const cookies = parseCookies();
  const [styleMap, setStyleMap] = useState("streets-v12");
  const [alertVisible, setAlertVisible] = useState(false);
  const [messageAlert, setMessageAlert] = useState(undefined);
  const [typeAlert, setTypeAlert] = useState(undefined);
  const [confirmLogout, setConfirmLogout] = useState(0);
  const [userActive, setUserActive] = useState([]);
  const [openModalLayerUpdate, setOpenModalLayerUpdate] = useState(false);
  const [dataUpdateLayer, setDataUpdateLayer] = useState([]);
  const [openDropDown, setOpenDropDown] = useState(false);
  const [openDropDownLokasi, setOpenDropDownLokasi] = useState(false);
  const [openOpenModalAddLokasi, setOpenModalAddLokasi] = useState(false);
  const [room, setRoom] = useState(undefined);
  const [nameArea, setNameArea] = useState("Pilih Area");
  const [nameLokasi, setLokasi] = useState("Pilih Lokasi");
  const [openModal, setOpenModal] = useState(false);
  const [openModalAddAdmin, setOpenModalAddAdmin] = useState(false);
  const [openModalQRCode, setOpenModalQRCode] = useState(false);
  const [openModalLogDaily, setOpenModalLogDaily] = useState(false);
  const [openModalChangeDataLocation, setOpenModalChangeDataLocation] =
    useState(false);
  const [lastAreaSelect, setLastAreaSelect] = useState([]);
  const [Area, setArea] = useState([]);
  const [openFeature, setOpenFeature] = useState(false);
  const [indexLokasi, setIndexLokasi] = useState(0);
  const [cardBroadcast, setCardBroadcast] = useState(false);
  const [openLivePreview, setOpenLivePreview] = useState(false);
  // Get the date for one week ago
  const currentDate = new Date().getTime();

  //logDataUserDaily
  const [userLogDaily, setUserLogDaily] = useState("");

  const [startDateExport, setStartDateExport] = useState([
    new Date(currentDate - 7 * 24 * 60 * 60 * 1000),
    new Date(),
  ]);

  const changeDateLogs = (onChange) => {
    setStartDateExport(onChange);
    setOpenLivePreview(false);
  };
  const mapContainer = useRef(null);
  const map = useRef(null);
  const draw = useRef(new MapboxDraw());
  const markerElements = useRef([]);

  //filter user
  const [sugest, setSugest] = useState([]);
  const [userFilterLogsExport, setUserFilterLogsExport] = useState({});
  const handleOnSearch = (string, results) => {
    // console.log("ini handle sonSearch",string, results);
    if (string.length > 3) {
      // console.log(string);
      getSugesty(string);
      // console.log(sugest);
    }
  };

  const handleOnSelect = (item) => {
    setUserFilterLogsExport(item);
    setOpenLivePreview(false);
  };

  const getSugesty = async (data) => {
    await axios
      .get(process.env.REACT_APP_BASE_URL + "users/searchUser?name=" + data, {
        headers: {
          Authorization: process.env.REACT_APP_TOKEN,
        },
      })
      .then((response) => {
        // console.log(response.data);
        setSugest(response.data);
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const handleRefreshDataArea = () => {
    axios
      .get(process.env.REACT_APP_BASE_URL + `area`, {
        headers: {
          Authorization: process.env.REACT_APP_TOKEN,
        },
      })
      .then(function (result) {
        // console.log(result);
        setArea(result.data);
        setNameArea("Pilih Area");
        setLokasi("Pilih Lokasi");
      })
      .catch(function (error) {
        // console.log(error);
      });
  };
  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BASE_URL + `area`, {
        headers: {
          Authorization: process.env.REACT_APP_TOKEN,
        },
      })
      .then(function (result) {
        if (cookies.role === "root") {
          setArea(result.data);
        } else {
          setArea(
            result.data.filter((data) => cookies.area.includes(data.code))
          );
        }
      })
      .catch(function (error) {
        // console.log(error);
      });
  }, []);

  useEffect(() => {
    mapboxgl.accessToken = process.env.REACT_APP_MAPTOKEN;
    // process.env.REACT_APP_MAPSTYLE
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/" + styleMap,
      center: [80, 36],
      zoom: 1,
      pitch: 0,
      bearing: 0,
    });

    map.current.on("style.load", () => {
      draw.current = new MapboxDraw({
        displayControlsDefault: true,
        controls: {
          polygon: true,
          trash: true,
          point: true,
        },
      });

      map.current.addControl(draw.current);

      map.current.on("draw.create", (e) => {
        const feature = e.features[0];
        if (feature.geometry.type === "Polygon") {
          const coordinates = feature.geometry.coordinates;
          // console.log("Data draw-polygon coordinates:", coordinates);
          setDataUpdateLayer(coordinates);
          setOpenModalLayerUpdate(true);
          setCardBroadcast(false);
        } else if (feature.geometry.type === "Point") {
          // console.log(feature.geometry.coordinates);
        }
      });
      map.current.setFog({
        color: "rgb(220, 159, 159)",
        "high-color": "rgb(36, 92, 223)",
        "horizon-blend": 0.4,
      });

      // map.current.addSource("mapbox-dem", {
      //   type: "raster-dem",
      //   url: "mapbox://mapbox.terrain-rgb",
      // });

      // map.current.setTerrain({
      //   source: "mapbox-dem",
      //   exaggeration: 0.1,
      // });
    });
    return () => map.current.remove();
  }, [styleMap, setStyleMap]);

  useEffect(() => {
    socket.on("active_users", (data) => {
      const threeDaysAgo = Date.now() - 3 * 24 * 60 * 60 * 1000;
      const recentUsers = data.filter(
        (user) => user.timestamps >= threeDaysAgo
      );
      setUserActive(recentUsers);
      markerElements.current.forEach((marker) => marker.remove());
      markerElements.current = [];
      data.map((data) => {
        if (data.location !== undefined && data.location.length > 0) {
          const newMarker = new mapboxgl.Marker()
            .setLngLat([
              parseFloat(data.location[0]),
              parseFloat(data.location[1]),
            ])
            .setPopup(new mapboxgl.Popup().setHTML(data.name))
            .addTo(map.current);
          markerElements.current.push(newMarker);
        }
      });
    });
  }, []);

  const flyToLocation = (coordinates) => {
    if (!map) return;
    map.current.flyTo({
      center: coordinates,
      zoom: 16,
      bearing: 130,
      pitch: 10,
      essential: true,
    });
  };

  const getAreaBounds = (area, room) => {
    if (map.current.getLayer(room)) {
      map.current.removeLayer(room);
      // console.log("Layer removed:", room);
    }

    // Now, check if the source exists and remove it
    if (map.current.getSource(room)) {
      map.current.removeSource(room);
      // console.log("Source removed:", room);
    }
    socket.emit("leave_room", room);
    axios
      .get(process.env.REACT_APP_BASE_URL + `area/locations/` + room, {
        headers: {
          Authorization: process.env.REACT_APP_TOKEN,
        },
      })
      .then(function (response) {
        // console.log("hasil", response.data.locations[0]);
        map.current.addSource(response.data.locations[0]._id, {
          type: "geojson",
          data: {
            type: "Feature",
            geometry: {
              type: "Polygon",
              // These coordinates outline Maine.
              coordinates: response.data.locations[0].bounds,
            },
          },
        });

        map.current.addLayer({
          id: response.data.locations[0]._id,
          type: "fill",
          source: response.data.locations[0]._id,
          layout: {},
          paint: {
            "fill-color": "#0080ff",
            "fill-opacity": 0.5,
          },
        });
        // Simpan ID layer baru ke state
        setRoom(response.data.locations[0]._id);
        joinRoom(
          response.data.locations[0]._id,
          cookies.userId,
          cookies.username,
          cookies.nohp
        );
        syncNewLocation(response.data.locations[0]._id);
        localStorage.setItem("area", nameArea);
        localStorage.setItem("location", area);
        localStorage.setItem("room", room);
      })
      .catch(function (error) {
        // console.log(error);
      });
  };

  const handleChangeArea = (index, element) => {
    console.log(nameLokasi);
    if (nameLokasi !== "Pilih Lokasi") {
      window.open(window.location.origin, "_blank");
    }
    setUserActive([]);
    // flyToLocation(Area[index].coordinates);
    setLastAreaSelect(Area[index].coordinates);
    setIndexLokasi(index);
    setNameArea(`${element.name}`);
    setOpenDropDown(!openDropDown);
    // getAreaBounds(element.name, element.room);
    setLokasi("Pilih Lokasi");
  };

  useEffect(() => {
    if (
      localStorage.getItem("room") &&
      nameArea !== "Pilih Area" &&
      nameLokasi !== "Pilih Lokasi"
    ) {
      joinRoom(
        localStorage.getItem("room"),
        cookies.userId,
        cookies.username,
        cookies.nohp
      );
    }
  }, []);

  const joinRoom = (room, userId, username, nohp) => {
    if (room !== "") {
      // console.log("room", room)
      socket.emit("join_room", { room, userId, username, nohp });
    }
  };

  const syncNewLocation = (room) => {
    if (room !== "") {
      // console.log("room", room)
      socket.emit("sync", room);
    }
  };
  const sendMessage = (data) => {
    socket.emit("send_broadcast", {
      room: room,
      name: "admin",
      message: data,
      audioBlob: null,
      time: new Date().getTime(),
    });
  };
  const SOSUSER = (data) => {
    if (data) {
      socket.emit("send_SOS", {
        userId: data.userId,
        room: data.roomid,
        name: data.name,
        sos: 0,
        nohp: data.nohp,
        location: data.location,
        time: new Date().getTime(),
      });
      setUserActive([]);
    }
  };

  const handleExportData = () => {
    axios
      .get(
        process.env.REACT_APP_BASE_URL +
          `geolog?startDate=${startDateExport[0].getTime()}&endDate=${startDateExport[1].getTime()}&room=${room}&name=${
            userFilterLogsExport.name
          }`,
        {
          headers: {
            Authorization: process.env.REACT_APP_TOKEN,
          },
        }
      )
      .then(function (response) {
        let data = [
          {
            sheet: userFilterLogsExport.name,
            columns: [
              { label: "Name", value: "name" },
              {
                label: "Latitude",
                value: (row) =>
                  row.location && row.location[1]
                    ? row.location[1].toString()
                    : "",
              },
              {
                label: "Longitude",
                value: (row) =>
                  row.location && row.location[0]
                    ? row.location[0].toString()
                    : "",
              },
              { label: "Status", value: "status" },
              { label: "Message", value: "message" },
              { label: "Timestamp", value: "timestamp" },
            ],
            content: response.data,
          },
        ];
        let settings = {
          fileName: "Data Logger User " + nameLokasi, // Name of the resulting spreadsheet
          extraLength: 3, // A bigger number means that columns will be wider
        };
        if (response.data) {
          xlsx(data, settings); // Will download the excel file
        }
        // console.log(response.data);
      })
      .catch(function (error) {
        // console.log(error);
      });
  };

  useEffect(() => {
    if (alertVisible === true) {
      setTimeout(() => {
        setAlertVisible(false);
      }, 8000);
    }
  }, [alertVisible]);

  const verifyLogout = () => {
    if (confirmLogout > 1) {
      handleLogOut();
    } else {
      setAlertVisible(true);
      setMessageAlert("Are you sure you're logged out? please click again");
      setTypeAlert("danger");
      setConfirmLogout(confirmLogout + 2);
    }
  };

  const generateQRcode = () => {
    setOpenModalQRCode(!openModalQRCode);
    // setAlertVisible(true);
    // setMessageAlert("Are you sure you're logged out? please click again");
    // setTypeAlert("info");
  };
  const handleCloseLayerUpdate = () => {
    setOpenModalLayerUpdate(false);
  };

  //
  const [widthPercentage, setWidthPercentage] = useState(25); // Inisialisasi dengan lebar awal

  const updateClassBasedOnScreenWidth = () => {
    const screenWidth = window.innerWidth;
    const newWidthPercentage = Math.min(25, screenWidth / 10); // Atur batas maksimal lebar menjadi 25%
    // console.log("tailwind", newWidthPercentage)
    setWidthPercentage(newWidthPercentage);
  };

  useEffect(() => {
    updateClassBasedOnScreenWidth();
  }, []);

  return (
    <div className="w-screen h-screen">
      <div ref={mapContainer} id="map" className="map" />
      <div
        className={`${
          alertVisible ? "scale-100 opacity-100" : "scale-90 opacity-0"
        } mr-10`}
      >
        <Alert data={messageAlert} type={typeAlert} />
      </div>
      <div className="absolute top-44 right-2 mr-1">
        {styleMap && styleMap === "streets-v12" ? (
          <div
            onClick={() => setStyleMap("satellite-streets-v12")}
            className="cursor-pointer bg-gray-50 rounded-md shadow-lg w-full h-auto mt-3 flex pl-1"
          >
            Satellite
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-6 h-6"
            >
              <path d="M11.644 1.59a.75.75 0 0 1 .712 0l9.75 5.25a.75.75 0 0 1 0 1.32l-9.75 5.25a.75.75 0 0 1-.712 0l-9.75-5.25a.75.75 0 0 1 0-1.32l9.75-5.25Z" />
              <path d="m3.265 10.602 7.668 4.129a2.25 2.25 0 0 0 2.134 0l7.668-4.13 1.37.739a.75.75 0 0 1 0 1.32l-9.75 5.25a.75.75 0 0 1-.71 0l-9.75-5.25a.75.75 0 0 1 0-1.32l1.37-.738Z" />
              <path d="m10.933 19.231-7.668-4.13-1.37.739a.75.75 0 0 0 0 1.32l9.75 5.25c.221.12.489.12.71 0l9.75-5.25a.75.75 0 0 0 0-1.32l-1.37-.738-7.668 4.13a2.25 2.25 0 0 1-2.134-.001Z" />
            </svg>
          </div>
        ) : (
          <div
            onClick={() => setStyleMap("streets-v12")}
            className="cursor-pointer bg-gray-50 rounded-md shadow-lg w-full h-auto mt-3 flex pl-1"
          >
            Streets
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-6 h-6"
            >
              <path d="M11.644 1.59a.75.75 0 0 1 .712 0l9.75 5.25a.75.75 0 0 1 0 1.32l-9.75 5.25a.75.75 0 0 1-.712 0l-9.75-5.25a.75.75 0 0 1 0-1.32l9.75-5.25Z" />
              <path d="m3.265 10.602 7.668 4.129a2.25 2.25 0 0 0 2.134 0l7.668-4.13 1.37.739a.75.75 0 0 1 0 1.32l-9.75 5.25a.75.75 0 0 1-.71 0l-9.75-5.25a.75.75 0 0 1 0-1.32l1.37-.738Z" />
              <path d="m10.933 19.231-7.668-4.13-1.37.739a.75.75 0 0 0 0 1.32l9.75 5.25c.221.12.489.12.71 0l9.75-5.25a.75.75 0 0 0 0-1.32l-1.37-.738-7.668 4.13a2.25 2.25 0 0 1-2.134-.001Z" />
            </svg>
          </div>
        )}
      </div>
      {openModalLayerUpdate && !cardBroadcast && (
        <div>
          <LayerTools
            dataUpdateLayer={dataUpdateLayer}
            nameArea={nameArea}
            nameLokasi={nameLokasi}
            room={room}
            handleCloseLayerUpdate={handleCloseLayerUpdate}
            getAreaBounds={getAreaBounds}
          />
        </div>
      )}

      {!openModalLayerUpdate && cardBroadcast && (
        <div className="absolute bottom-32 right-10 ">
          <div className="bg-blue-200 bg-opacity-50 rounded-lg shadow-lg w-full h-auto mt-3">
            <BroadcastTabs sendMessage={sendMessage} />
          </div>
        </div>
      )}

      <div className="absolute bottom-10 right-5 gap-3 inline-flex">
        <div className="inline-flex gap-3 transition-all duration-300 ease-out md:ease-in">
          <div
            onClick={verifyLogout}
            className={`cursor-pointer h-12 w-12 rounded-full p-3 shadow-lg transform transition-all duration-500 ease-in-out delay-50 ${
              openFeature
                ? "scale-100 translate-y-0 opacity-100"
                : "scale-0 -translate-y-4 opacity-0"
            } ${openModal ? "bg-sky-300" : "bg-gray-300"}`}
          >
            <LogOutIcon />
          </div>
          {nameLokasi !== "Pilih Lokasi" && (
            <>
              <div
                onClick={generateQRcode}
                className={`cursor-pointer h-12 w-12 rounded-full p-3 shadow-lg transform transition-all duration-500 ease-in-out delay-50 ${
                  openFeature
                    ? "scale-100 translate-y-0 opacity-100"
                    : "scale-0 -translate-y-4 opacity-0"
                } ${openModal ? "bg-sky-300" : "bg-gray-300"}`}
              >
                <QRIcon />
              </div>
              <div
                onClick={() => {
                  setOpenModal(!openModal);
                  setOpenLivePreview(false);
                }}
                className={`cursor-pointer h-12 w-12 rounded-full p-3 shadow-lg transform transition-all duration-500 ease-in-out delay-100 ${
                  openFeature
                    ? "scale-100 translate-y-0 opacity-100"
                    : "scale-0 -translate-y-4 opacity-0"
                } ${openModal ? "bg-sky-300" : "bg-gray-300"}`}
              >
                <ExportFileIcon />
              </div>
              <div
                onClick={() => {
                  setCardBroadcast(!cardBroadcast);
                  setAlertVisible(true);
                  setMessageAlert(
                    "You Have a Broadcast to any people on the location"
                  );
                  setOpenModalLayerUpdate(false);
                  setTypeAlert("info");
                }}
                className={`cursor-pointer h-12 w-12 rounded-full  p-3 shadow-lg transform transition-all duration-500 ease-in-out delay-200 ${
                  openFeature
                    ? "scale-100 translate-y-0 opacity-100"
                    : "scale-0 -translate-y-4 opacity-0"
                } ${cardBroadcast ? "bg-sky-300" : "bg-gray-300"}`}
              >
                <BroadcastIcon />
              </div>
              <div
                onClick={() => setOpenModalChangeDataLocation(true)}
                className={`cursor-pointer h-12 w-12 rounded-full p-3 shadow-lg transform transition-all duration-500 ease-in-out delay-300 ${
                  openFeature
                    ? "scale-100 translate-y-0 opacity-100"
                    : "scale-0 -translate-y-4 opacity-0"
                } ${
                  openModalChangeDataLocation ? "bg-sky-300" : "bg-gray-300"
                }`}
              >
                <LocationIcon />
              </div>
              {cookies.role === "root" && (
                <div
                  onClick={() => setOpenModalAddAdmin(!openModalAddAdmin)}
                  className={`cursor-pointer h-12 w-12 rounded-full p-3 shadow-lg transform transition-all duration-500 ease-in-out delay-300 ${
                    openFeature
                      ? "scale-100 translate-y-0 opacity-100"
                      : "scale-0 -translate-y-4 opacity-0"
                  } ${openModalAddAdmin ? "bg-sky-300" : "bg-gray-300"}`}
                >
                  <GroupAdminIcon />
                </div>
              )}
            </>
          )}
        </div>

        <div
          onClick={() => setOpenFeature(!openFeature)}
          class="cursor-pointer h-16 w-16 rounded-full bg-gray-300 p-5 shadow-lg"
        >
          <svg
            className="hover:animate-pulse"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 2C11.4477 2 11 2.44772 11 3V11H3C2.44772 11 2 11.4477 2 12C2 12.5523 2.44772 13 3 13H11V21C11 21.5523 11.4477 22 12 22C12.5523 22 13 21.5523 13 21V13H21C21.5523 13 22 12.5523 22 12C22 11.4477 21.5523 11 21 11H13V3C13 2.44772 12.5523 2 12 2Z"
              fill="black"
              stroke="black"
              strokeWidth="2"
            />
          </svg>
        </div>
      </div>
      <div class="absolute left-0 top-0 p-10 h-full w-full sm:w-full md:w-1/2 ">
        <div className="bg-blue-200 bg-opacity-50 rounded-lg shadow-lg w-full h-9/12">
          <div className="p-3 w-full">
            <button
              onClick={() => {
                setOpenDropDown(!openDropDown);
                setOpenDropDownLokasi(false);
              }}
              className="p-3 border-2 border-gray-250 text-gray-900 rounded-lg text-lg font-bold w-full bg-gray-300 shadow-lg hover:bg-sky-900 hover:text-gray-50 hover:border-sky-900"
            >
              {nameArea === "Pilih Area" ? nameArea : <>Area {nameArea}</>}
            </button>
          </div>
          {openDropDown && (
            <div className="mx-4 shadow-lg absolute z-10 bg-gray-300 divide-y divide-gray-200 rounded-lg shadow w-72 dark:bg-gray-700">
              <ul
                className="py-2 text-sm text-gray-700 dark:text-gray-200"
                aria-labelledby="dropdownDefaultButton"
              >
                <li>
                  {Area.map((element, index) => (
                    <div
                      onClick={() => {
                        handleChangeArea(index, element);
                      }}
                      key={element.name}
                      className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white cursor-pointer"
                    >
                      {`Area ${element.name}`}
                    </div>
                  ))}
                </li>
              </ul>
            </div>
          )}
          {nameArea !== "Pilih Area" && (
            <div className="p-3 w-full">
              <button
                onClick={() => {
                  setOpenDropDown(false);
                  setOpenDropDownLokasi(!openDropDownLokasi);
                }}
                className="p-3 border-2 border-gray-250 text-gray-900 rounded-lg text-lg font-bold w-full bg-gray-300 shadow-lg hover:bg-sky-900 hover:text-gray-50 hover:border-sky-900"
              >
                {nameLokasi}
              </button>
            </div>
          )}
          {openDropDownLokasi && (
            <div className="mx-4 shadow-lg absolute z-10 bg-gray-300 divide-y divide-gray-200 rounded-lg shadow w-72 dark:bg-gray-700">
              <ul
                className="py-2 text-sm text-gray-700 dark:text-gray-200"
                aria-labelledby="dropdownDefaultButton"
              >
                <li className="max-h-60 overflow-auto scroll-auto">
                  {Area[indexLokasi].locations.map((data) => (
                    <div
                      onClick={() => {
                        setUserActive([]);
                        flyToLocation(data.coordinates);
                        setLokasi(`${data.name}`);
                        getAreaBounds(data.name, data._id);
                        setOpenDropDownLokasi(!openDropDownLokasi);
                      }}
                      key={data.name}
                      className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white cursor-pointer"
                    >
                      {`${data.name}`}
                    </div>
                  ))}
                </li>
                <li>
                  <div className="block px-4 py-2">
                    <div
                      onClick={() =>
                        setOpenModalAddLokasi(!openOpenModalAddLokasi)
                      }
                      className="flex gap-2 bg-sky-900 cursor-pointer rounded-full dark:bg-gray-500 hover:bg-blue-800 dark:hover:bg-blue-500 dark:hover:text-white"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        class="w-5 h-5 text-gray-50"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25ZM12.75 9a.75.75 0 0 0-1.5 0v2.25H9a.75.75 0 0 0 0 1.5h2.25V15a.75.75 0 0 0 1.5 0v-2.25H15a.75.75 0 0 0 0-1.5h-2.25V9Z"
                          clip-rule="evenodd"
                        />
                      </svg>
                      <p className="text-gray-50 text-medium">
                        Tambahkan Lokasi
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          )}
          <InfoTabs
            setUserLogDaily={setUserLogDaily}
            setOpenModalLogDaily={setOpenModalLogDaily}
            joinRoom={joinRoom}
            listArea={Area}
            userActive={userActive}
            SOSUSER={SOSUSER}
          />
        </div>
      </div>
      {openOpenModalAddLokasi && (
        <div class="bg-gray-900/50 fixed top-0 left-0 right-0 z-50 w-full h-full p-4 overflow-x-hidden">
          <div class="flex items-center justify-center h-full">
            <div class="relative bg-white rounded-lg shadow dark:bg-gray-700 w-1/3">
              <button
                onClick={() => setOpenModalAddLokasi(!openOpenModalAddLokasi)}
                type="button"
                class="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                data-modal-hide="authentication-modal"
              >
                <svg
                  class="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span class="sr-only">Close modal</span>
              </button>
              <div class="px-6 py-6 lg:px-8">
                <h3 class="mb-4 text-xl font-medium text-gray-900 dark:text-white">
                  Tambahkan Lokasi di Area {nameArea}
                </h3>
                <AddNewLocation
                  nameArea={nameArea}
                  AreaID={Area[indexLokasi]._id}
                  setOpenModalAddLokasi={setOpenModalAddLokasi}
                  handleRefreshDataArea={handleRefreshDataArea}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {openModal && nameArea !== "Pilih Area" && (
        <div class="bg-gray-900/50 fixed top-0 left-0 right-0 z-50 w-full h-full p-4 overflow-x-hidden">
          <div class="flex items-center justify-center h-full">
            {openLivePreview && (
              <div className="bg-gray-700 py-7 px-4 rounded-l-lg">
                <ExportTrack
                  room={room}
                  startDate={startDateExport[0]}
                  endDate={startDateExport[1]}
                  name={userFilterLogsExport.name}
                />
              </div>
            )}
            <div class="relative bg-white rounded-r-lg shadow dark:bg-gray-700 w-auto min-w-64">
              <button
                onClick={() => setOpenModal(!openModal)}
                type="button"
                class="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                data-modal-hide="authentication-modal"
              >
                <svg
                  class="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span class="sr-only">Close modal</span>
              </button>
              <div class="px-6 py-6">
                <h3 class="mb-4 text-xl font-medium text-gray-900 dark:text-white">
                  Export Logs User
                </h3>
                <div class="space-y-6">
                  <div className="w-auto z-50">
                    <ReactSearchAutocomplete
                      className="z-50"
                      items={sugest}
                      onSearch={handleOnSearch}
                      onSelect={handleOnSelect}
                      autoFocus
                    />
                  </div>
                  <div>
                    <label
                      for="area"
                      class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Area
                    </label>
                    <input
                      type="text"
                      name="area"
                      id="name"
                      value={nameArea}
                      class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                      required
                    />
                  </div>
                  <div>
                    <label
                      for="area"
                      class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Lokasi
                    </label>
                    <input
                      type="text"
                      name="area"
                      id="name"
                      value={nameLokasi}
                      class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                      required
                    />
                  </div>
                  <div class="w-full px-1 dark:bg-gray-300 rounded-lg">
                    <DateRangePicker
                      onChange={(onChange) => changeDateLogs(onChange)}
                      value={startDateExport}
                      format="yy-MM-dd"
                    />
                  </div>
                  <div className="flex gap-2 ">
                    <button
                      onClick={() => handleExportData()}
                      class="w-full text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
                    >
                      Export to xlsx
                    </button>
                    <div
                      onClick={() => setOpenLivePreview(true)}
                      className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    >
                      Preview
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {openModalAddAdmin && (
        <div class="bg-gray-900/50 fixed top-0 left-0 right-0 z-50 w-full h-full p-4 overflow-x-hidden">
          <div class="flex items-center justify-center h-full">
            <div class="relative bg-white rounded-lg shadow dark:bg-gray-700 w-1/3">
              <button
                onClick={() => setOpenModalAddAdmin(!openModalAddAdmin)}
                type="button"
                class="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                data-modal-hide="authentication-modal"
              >
                <svg
                  class="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span class="sr-only">Close modal</span>
              </button>
              <div class="px-6 py-6 lg:px-8">
                <h3 class="mb-4 text-xl font-medium text-gray-900 dark:text-white">
                  Administrator I-PIT {nameArea}
                </h3>
                <ListAdmin nameArea={nameArea} nameLokasi={nameLokasi} />
              </div>
            </div>
          </div>
        </div>
      )}
      {openModalQRCode && (
        <div class="bg-gray-900/50 fixed top-0 left-0 right-0 z-50 w-full h-full p-4 overflow-x-hidden">
          <div class="flex items-center justify-center h-full">
            <div class="relative bg-white rounded-lg shadow dark:bg-gray-700 w-1/3">
              <button
                onClick={() => setOpenModalQRCode(!openModalQRCode)}
                type="button"
                class="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                data-modal-hide="authentication-modal"
              >
                <svg
                  class="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span class="sr-only">Close modal</span>
              </button>
              <div class="px-6 py-6 lg:px-8">
                <h3 class="mb-4 text-xl font-medium text-gray-900  dark:text-white">
                  QR Generator Gate Area
                </h3>
                <ListQRArea
                  room={room}
                  nameArea={nameArea}
                  lastAreaSelect={lastAreaSelect}
                  dataJson={Area[indexLokasi]}
                  setOpenModalChangeDataLocation={
                    setOpenModalChangeDataLocation
                  }
                />
                {/* <ListAdmin nameArea={nameArea} nameLokasi={nameLokasi} /> */}
              </div>
            </div>
          </div>
        </div>
      )}
      {openModalChangeDataLocation && (
        <div class="bg-gray-900/50 fixed top-0 left-0 right-0 z-50 w-full h-full p-4 overflow-x-hidden">
          <div class="flex items-center justify-center h-full">
            <div class="relative bg-white rounded-lg shadow dark:bg-gray-700 w-1/3">
              <button
                onClick={() =>
                  setOpenModalChangeDataLocation(!openModalChangeDataLocation)
                }
                type="button"
                class="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                data-modal-hide="authentication-modal"
              >
                <svg
                  class="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span class="sr-only">Close modal</span>
              </button>
              <div class="px-6 py-6 lg:px-8">
                <h3 class="mb-4 text-xl font-medium text-gray-900 dark:text-white">
                  Change Information Area {nameArea}
                </h3>
                <ListLocation
                  room={room}
                  nameArea={nameArea}
                  lastAreaSelect={lastAreaSelect}
                  dataJson={Area[indexLokasi].locations}
                  AreaID={Area[indexLokasi]._id}
                  setOpenModalChangeDataLocation={
                    setOpenModalChangeDataLocation
                  }
                  handleRefreshDataArea={handleRefreshDataArea}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {openModalLogDaily && (
        <div class="bg-gray-900/60 fixed top-0 left-0 right-0 z-50 w-full h-full p-4 overflow-x-hidden">
          <div class="flex items-center justify-center h-full">
            <div class="relative bg-gray-200 rounded-lg shadow dark:bg-gray-700 w-1/2">
              <button
                onClick={() => setOpenModalLogDaily(!openModalLogDaily)}
                type="button"
                class="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                data-modal-hide="authentication-modal"
              >
                <svg
                  class="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span class="sr-only">Close modal</span>
              </button>
              <div class="px-6 py-6 lg:px-8">
                <h3 class="mb-4 text-xl font-medium text-gray-900 dark:text-white">
                  Daily Log User {nameArea}
                </h3>
                <ListUserLogs username={userLogDaily} nameLokasi={nameLokasi} />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Map;
