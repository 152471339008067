import axios from "axios";
import React, { useState } from "react";

export default function ListLocation({
  room,
  nameArea,
  lastAreaSelect,
  dataJson,
  AreaID,
  setOpenModalChangeDataLocation,
  handleRefreshDataArea
}) {
  const [nameAreaUpdate, setNameAreaUpdate] = useState(nameArea);
  const [areaLocationUpdateLat, setAreaLocationUpdateLat] = useState(
    lastAreaSelect[1]
  );
  const [areaLocationUpdateLon, setAreaLocationUpdateLon] = useState(
    lastAreaSelect[0]
  );
  const [dataLocation, setDataLocation] = useState(dataJson);

  const [openModalEditLocation, setOpenModalEditLocation] = useState(false)
  const [editedLocationIndex, setEditedLocationIndex] = useState(null);
  const [editedLocationData, setEditedLocationData] = useState({
    _id: "",
    name: "",
    code:"",
    coordinates: [0, 0], // Atur nilai default sesuai kebutuhan
    bounds: [], // Atur nilai default sesuai kebutuhan
  });

  const handleDeleteLocation = (index) => {
    const updatedDataLocation = [...dataLocation];
    updatedDataLocation.splice(index, 1);

    // console.log("delete", updatedDataLocation)
    setDataLocation(updatedDataLocation);
  };

  // hanlde untuk set data yang akan diedit
  const handleEditLocation = (index) => {
    setEditedLocationIndex(index);
    const editedLocation = dataLocation[index];
    setEditedLocationData({
      _id: editedLocation._id,
      name: editedLocation.name,
      code: editedLocation.code,
      coordinates: editedLocation.coordinates,
      bounds: editedLocation.bounds,
    });
  };

  const handleSaveEditedLocation = async () => {
    const updatedDataLocation = dataLocation.map((location, index) =>
      index === editedLocationIndex ? editedLocationData : location
    );
    setDataLocation(updatedDataLocation);
    await axios
      .put(
        process.env.REACT_APP_BASE_URL + `area/` + AreaID,
        {
          name: nameAreaUpdate,
          coordinates: [areaLocationUpdateLon, areaLocationUpdateLat],
          locations: updatedDataLocation
        },
        {
          headers: {
            Authorization: process.env.REACT_APP_TOKEN,
          },
        }
      )
      .then(function (result) {
        // console.log(result);
        setOpenModalChangeDataLocation(false)
        handleRefreshDataArea()
      })
      .catch(function (error) {
        // setMessageAlert("Please contact your Administrator");
        // setTypeAlert("danger");
        // setAlertVisible(true);
        // console.log(error);
      });
    setEditedLocationIndex(null);
    setEditedLocationData({
      name: "",
      code: "",
      coordinates: [0, 0], // Atur nilai default sesuai kebutuhan
      bounds: [], // Atur nilai default sesuai kebutuhan
    });
  };


  const handleUpdateArea = async () => {
    await axios
      .put(
        process.env.REACT_APP_BASE_URL + `area/` + AreaID,
        {
          name: nameAreaUpdate,
          coordinates: [areaLocationUpdateLon, areaLocationUpdateLat],
          locations: dataLocation
        },
        {
          headers: {
            Authorization: process.env.REACT_APP_TOKEN,
          },
        }
      )
      .then(function (result) {
        // console.log(result);
        setOpenModalChangeDataLocation(false)
        handleRefreshDataArea()
      })
      .catch(function (error) {
        // setMessageAlert("Please contact your Administrator");
        // setTypeAlert("danger");
        // setAlertVisible(true);
        // console.log(error);
      });
  };

  return (
    <div className="m-3">
      <div class="bg-white w-full dark:bg-gray-700">
        <div>
          {
            dataLocation &&
              openModalEditLocation ?
              <>
                <div class="max-w-sm mx-auto">
                  <div class="mb-5">
                    <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Location Name</label>
                    <input
                      onChange={(e) => setEditedLocationData({ ...editedLocationData, name: e.target.value })}
                      defaultValue={dataLocation[editedLocationIndex].name}
                      type="text" id="name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="assembly point" required />
                  </div>
                  <div class="mb-5">
                    <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Location Code</label>
                    <input
                      onChange={(e) => setEditedLocationData({ ...editedLocationData, code: e.target.value })}
                      defaultValue={dataLocation[editedLocationIndex].code}
                      type="text" id="name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="*Optional to join System Mevent" />
                  </div>
                  <div class="mb-5 flex gap-2">
                    <div>
                      <label for="latitude" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Latitude</label>
                      <input
                        onChange={(e) => setEditedLocationData({
                          ...editedLocationData,
                          coordinates: [editedLocationData.coordinates[0], parseFloat(e.target.value)]
                        })}
                        defaultValue={dataLocation[editedLocationIndex].coordinates[1]}
                        type="number" id="latitude"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
                    </div>
                    <div>
                      <label for="longitude" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Longitude</label>
                      <input
                        onChange={(e) => setEditedLocationData({
                          ...editedLocationData,
                          coordinates: [parseFloat(e.target.value), editedLocationData.coordinates[1]]
                        })}
                        defaultValue={dataLocation[editedLocationIndex].coordinates[0]}
                        type="number" id="longitude"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
                    </div>
                  </div>

                  <div className="flex gap-2">
                    <button onClick={() => setOpenModalEditLocation(false)} class="text-white bg-gray-400 hover:bg-gray-300 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Cancel</button>
                    <button onClick={() => handleSaveEditedLocation()} class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Update Location</button>
                  </div>
                </div>
              </>
              :
              <>
                <div class="relative z-0 w-full mb-6 group">
                  <input
                    onChange={(e) => setNameAreaUpdate(e.target.value)}
                    type="email"
                    name="floating_email"
                    id="floating_email"
                    class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                  />
                  <label
                    for="floating_email"
                    class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                  >
                    {nameArea}
                  </label>
                </div>
                <div class="grid md:grid-cols-2 md:gap-6">
                  <div class="relative z-0 w-full mb-6 group">
                    <input
                      onChange={(e) => setAreaLocationUpdateLat(e.target.value)}
                      type="number"
                      name="floating_first_name"
                      id="floating_first_name"
                      class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=" "
                    />
                    <label
                      for="floating_first_name"
                      class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                      {lastAreaSelect[1]}
                    </label>
                  </div>
                  <div class="relative z-0 w-full mb-6 group">
                    <input
                      onChange={(e) => setAreaLocationUpdateLon(e.target.value)}
                      type="number"
                      name="floating_last_name"
                      id="floating_last_name"
                      class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=""
                    />
                    <label
                      for="floating_last_name"
                      class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                      {lastAreaSelect[0]}
                    </label>
                  </div>
                </div>
                <div className="relative max-h-60 overflow-auto scroll-auto">
                  {
                    dataLocation.map((data, index) =>
                      <>
                        <div className="bg-gray-100 px-2 py-1 rounded-lg mb-2 flex justify-between">
                          <p>
                            {data.name}
                          </p>
                          <div className="flex gap-2">
                            <svg
                              onClick={() => {
                                handleEditLocation(index)
                                setOpenModalEditLocation(true)
                              }}
                              xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5 cursor-pointer hover:text-blue-500">
                              <path d="M21.731 2.269a2.625 2.625 0 0 0-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 0 0 0-3.712ZM19.513 8.199l-3.712-3.712-8.4 8.4a5.25 5.25 0 0 0-1.32 2.214l-.8 2.685a.75.75 0 0 0 .933.933l2.685-.8a5.25 5.25 0 0 0 2.214-1.32l8.4-8.4Z" />
                              <path d="M5.25 5.25a3 3 0 0 0-3 3v10.5a3 3 0 0 0 3 3h10.5a3 3 0 0 0 3-3V13.5a.75.75 0 0 0-1.5 0v5.25a1.5 1.5 0 0 1-1.5 1.5H5.25a1.5 1.5 0 0 1-1.5-1.5V8.25a1.5 1.5 0 0 1 1.5-1.5h5.25a.75.75 0 0 0 0-1.5H5.25Z" />
                            </svg>
                            <svg
                              onClick={() => handleDeleteLocation(index)}
                              xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5 cursor-pointer hover:text-red-500">
                              <path fill-rule="evenodd" d="M16.5 4.478v.227a48.816 48.816 0 0 1 3.878.512.75.75 0 1 1-.256 1.478l-.209-.035-1.005 13.07a3 3 0 0 1-2.991 2.77H8.084a3 3 0 0 1-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 0 1-.256-1.478A48.567 48.567 0 0 1 7.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 0 1 3.369 0c1.603.051 2.815 1.387 2.815 2.951Zm-6.136-1.452a51.196 51.196 0 0 1 3.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 0 0-6 0v-.113c0-.794.609-1.428 1.364-1.452Zm-.355 5.945a.75.75 0 1 0-1.5.058l.347 9a.75.75 0 1 0 1.499-.058l-.346-9Zm5.48.058a.75.75 0 1 0-1.498-.058l-.347 9a.75.75 0 0 0 1.5.058l.345-9Z" clip-rule="evenodd" />
                            </svg>

                          </div>
                        </div>
                      </>
                    )
                  }
                </div>
                <button
                  onClick={handleUpdateArea}
                  class="cursor-pointer mt-5 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  Update Area
                </button>
              </>
          }
        </div>
      </div>
    </div>
  );
}
