import React from "react";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";

export default function ListUserLogs({ username, nameLokasi }) {
  const [data, setData] = useState();
  const [loader, setLoader] = useState(true);

  const handleGetLogDaily = (name) => {
    axios
      .get(process.env.REACT_APP_BASE_URL + `geolog/user-log/` + name, {
        headers: {
          Authorization: process.env.REACT_APP_TOKEN,
        },
      })
      .then(function (response) {
        setData(response.data);
        setLoader(false);
      })
      .catch(function (error) {
        // console.log(error);
      });
    // console.log("data", data)
  };

  useEffect(() => {
    if (username !== undefined || username !== "") handleGetLogDaily(username);
  }, []);

  return (
    <div className="m-3">
      {loader === true ? (
        <>
        <div className="w-full justify-center flex mt-10">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            class="w-12 h-12 animate-spin text-white"
          >
            <path
              fill-rule="evenodd"
              d="M4.755 10.059a7.5 7.5 0 0 1 12.548-3.364l1.903 1.903h-3.183a.75.75 0 1 0 0 1.5h4.992a.75.75 0 0 0 .75-.75V4.356a.75.75 0 0 0-1.5 0v3.18l-1.9-1.9A9 9 0 0 0 3.306 9.67a.75.75 0 1 0 1.45.388Zm15.408 3.352a.75.75 0 0 0-.919.53 7.5 7.5 0 0 1-12.548 3.364l-1.902-1.903h3.183a.75.75 0 0 0 0-1.5H2.984a.75.75 0 0 0-.75.75v4.992a.75.75 0 0 0 1.5 0v-3.18l1.9 1.9a9 9 0 0 0 15.059-4.035.75.75 0 0 0-.53-.918Z"
              clip-rule="evenodd"
            />
          </svg>
        </div>
        </>
      ) : (
        <>
          <div className="bg-gray-200 w-full rounded-lg dark:bg-gray-700 max-h-60 overflow-y-auto">
            <table className="w-full">
              <thead>
                <tr className="bg-gray-800 text-gray-200 rounded-lg">
                  <th className="px-4 py-2 text-left">Name</th>
                  <th className="px-4 py-2 text-left">Activity</th>
                  <th className="px-4 py-2 text-left">Time</th>
                </tr>
              </thead>
              <tbody className="dark:text-gray-200 ">
                {data.map((data) => (
                  <tr
                    key={data._id}
                    className="hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                  >
                    <td className="px-4 py-2">{data.name}</td>
                    <td className="px-4 py-2 font-semibold">{data.message}</td>
                    <td className="px-4 py-2">
                      {new Date(parseInt(data.timestamp)).toLocaleTimeString(
                        "id-ID",
                        {
                          hour: "numeric",
                          minute: "numeric",
                          second: "numeric",
                          hour12: true, // atau false, tergantung apakah Anda ingin format 12 jam atau 24 jam
                          timeZone: "Asia/Jakarta", // Tetapkan zona waktu ke WIB (UTC+7)
                        }
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}
    </div>
  );
}
