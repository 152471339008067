import React, { useEffect, useState } from "react";
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import axios from "axios";
TimeAgo.setDefaultLocale(en.locale)
TimeAgo.addLocale(en)

export default function InfoTabs({ userActive, setOpenModalLogDaily, setUserLogDaily, SOSUSER }) {
  const timeAgo = new TimeAgo('en');
  const [floorData, setFloorData] = useState([]);
  const [totalAgency, setTotalAgency] = useState({
    guest: 0,
    PGE: 0,
    guestOffline: 0,
    PGEOffline: 0,
  });

  const getFloorFromActivity = (activity) => {
    const match = activity.match(/lt(\d+)/i);
    return match ? match[1] : ' unknown';
  };
  useEffect(() => {
    if (userActive && userActive.length > 0) {
      const newFloorData = {};
      const newTotalAgency = {
        guest: 0,
        PGE: 0,
        guestOffline: 0,
        PGEOffline: 0,
      };

      userActive.forEach((element) => {
        if (element.fencing === 1 && element.message !== undefined) {
          const floor = getFloorFromActivity(element.message);

          // Update or initialize the count for the floor
          newFloorData[floor] = (newFloorData[floor] || 0) + 1;

          // Update or initialize the count for the agency
        }
      });

      userActive.forEach((element) => {
        if (element.fencing === 1) {
          if (element.agency === "PGE") {
            newTotalAgency.PGE += 1;
          } else if (element.agency === "Tamu") {
            newTotalAgency.guest += 1;
          } else if (element.agency === "Guest") {
            newTotalAgency.guest += 1;
          }
        } else {
          if (element.agency === "PGE") {
            newTotalAgency.PGEOffline += 1;
          } else if (element.agency === "Tamu") {
            newTotalAgency.guestOffline += 1;
          } else if (element.agency === "Guest") {
            newTotalAgency.guestOffline += 1;
          }
        }
      })
      // Convert the object into an array of objects
      const newDataArray = Object.entries(newFloorData).map(([floor, total]) => ({
        floor: floor,
        total: total,
      }));
      setFloorData(newDataArray);
      setTotalAgency(newTotalAgency);
    } else {
      const newTotalAgency = {
        guest: 0,
        PGE: 0,
      };
      setFloorData([]);
      setTotalAgency(newTotalAgency);
    }
  }, [userActive]);

  const sortedActiveUsers = userActive
    ? userActive
      .filter((element) => element.fencing === 1)
      .sort((a, b) => {
        // Sort SOS data first, if present
        if (a.sos === 1 && b.sos !== 1) {
          return -1; // a comes first
        } else if (a.sos !== 1 && b.sos === 1) {
          return 1; // b comes first
        } else {
          // Sort by timestamps for other cases
          return b.timestamps - a.timestamps;
        }
      })
    : [];

  const sortedOfflineUsers = userActive
    ? userActive
      .filter((element) => element.fencing === 0)
      .sort((a, b) => {
        // Sort SOS data first, if present
        if (a.sos === 1 && b.sos !== 1) {
          return -1; // a comes first
        } else if (a.sos !== 1 && b.sos === 1) {
          return 1; // b comes first
        } else {
          // Sort by timestamps for other cases
          return b.timestamps - a.timestamps;
        }
      })
    : [];


  const handleGetLogDaily = (data) => {
    if(data.name !== null){
      setOpenModalLogDaily(true)
      setUserLogDaily(data.name)
    }
    // axios
    //   .get(process.env.REACT_APP_BASE_URL + `geolog/user-log/` + data.name, {
    //     headers: {
    //       Authorization: process.env.REACT_APP_TOKEN,
    //     },
    //   })
    //   .then(function (response) {
    //     console.log(response)
    //     setOpenModalLogDaily(true)
    //     setUserLogDaily(response.data)
    //     // console.log("response", response)
    //   })
    //   .catch(function (error) {
    //     // console.log(error);
    //   });
    // // console.log("data", data)
  }

  const handleSOS = (data) => {
    SOSUSER(data)
  }
  return (
    <div className="p-3 text-gray-950">
      <div className="mt-3 bg-green-500 bg-opacity-70 w-auto px-2 rounded-t-lg py-1 shadow-lg font-bold text-gray-900 flex justify-between">
        <p className="truncate">
          {userActive.filter((element) => element.fencing === 1).length} user in Location
        </p>
        <div className="flex text-xs  rounded-lg px-2 gap-2 py-1">
          <div>
            <p className="bg-blue-200 rounded-lg pl-1 truncate">
              {totalAgency.guest}
              <span className="bg-blue-500 rounded-lg px-1 text-gray-100">
                Guest
              </span>
            </p>
          </div>
          <div>
            <p className="bg-green-200 rounded-lg pl-1 truncate">
              {totalAgency.PGE}
              <span className="bg-green-500 rounded-lg px-1 text-gray-100">
                PGE
              </span>
            </p>
          </div>
          {floorData.map(data =>
            <div>
              <p className="bg-orange-200 rounded-lg pl-1 truncate">
                {data.total}
                <span className="bg-orange-500 rounded-lg px-1 text-gray-100">
                  LT{data.floor}
                </span>
              </p>
            </div>
          )}
        </div>
      </div>
      <div className="pt-1 px-2 text-xs w-full h-56 max-h-56 overflow-y-scroll rounded-b-lg border-b-2 border-x-2 border-gray-200 bg-gray-200 bg-opacity-80 pb-3 shadow-lg">
        {userActive && userActive.length !== 0 && sortedActiveUsers.map((element) => (
          <>
            <div
              className={`p-1 ${element.sos === 1 && "rounded-lg bg-orange-400 animate-pulse"} mb-1 hover:bg-gray-300`}>
              <div
                className="flex mt-2 justify-between px-5 gap-1  align-middle"
                key={element}
              >
                {element.fencing !== 0
                  &&
                  <>
                    <div className="cursor-pointer flex justify-start gap-1  align-middle">
                      <div className={`w-2 h-2 rounded-full mt-1 ${element.status === 2 && "bg-gray-700"} ${element.status === 1 && "bg-green-700 animate-pulse"} ${element.status === 0 && "bg-red-700"}`} />
                      <p className="text-gray-950 font-bold flex gap-2">
                        <p
                          onClick={() => handleGetLogDaily(element)}
                          className={`truncate cursor-pointer ${element.agency !== "PGE" ? "bg-blue-200 px-1 rounded-lg" : "bg-green-200 px-1 bg-opacity-80 rounded-lg"}`}>
                          {
                            typeof element.name === "string" &&
                            element.name.replace(/\d+/g, '').trim()}
                        </p>
                        {
                          element.timestamps !== undefined &&
                          <p className="text-gray-600 text-xs cursor-default">last seen {timeAgo.format(new Date(parseInt(element.timestamps)))}
                            {/* {
                            new Date(parseInt(element.timestamps))
                              .toLocaleTimeString(
                                "id-ID",
                                {
                                  hour: "numeric",
                                  minute: "numeric",
                                  second: "numeric",
                                  hour12: true, // atau false, tergantung apakah Anda ingin format 12 jam atau 24 jam
                                  timeZone: "Asia/Jakarta" // Tetapkan zona waktu ke WIB (UTC+7)
                                }
                              )} */}
                          </p>
                        }
                      </p>
                    </div>
                    <div className="flex gap-1">
                      {element.message &&
                        // element.message.match(/Lt/i) && (
                        <div className="">
                          <span className="bg-gray-300 px-1 rounded-full font-medium">
                            {/* LT${element.message.split(/Lt/i)[1].split(" ")[0]} */}
                            {/* {element.message.match(/Lt/i) ? `${element.message}` : ''} */}
                            {element.message}
                          </span>
                        </div>
                        // )}
                      }
                    </div>
                  </>
                }
              </div>

              {element.sos === 1 &&

                <div className="pl-8 pr-1 flex justify-between">
                  <div>
                    <span>
                      {`SOS active ! current location ${element.location[1]}, ${element.location[0]} on `}
                    </span>
                    <span>
                      {
                        new Date(parseInt(element.timestamps)).toLocaleDateString("id-ID", {
                          month: "long",
                          day: "numeric",
                          year: "numeric",
                        }) +
                        " at " +
                        new Date(parseInt(element.timestamps)).toLocaleTimeString(
                          "id-ID",
                          {
                            hour: "numeric",
                            minute: "numeric",
                            second: "numeric",
                            hour12: true, // atau false, tergantung apakah Anda ingin format 12 jam atau 24 jam
                          }
                        )}
                    </span>
                  </div>
                  <div className="flex gap-2 z-10">
                    <div onClick={() => handleSOS(element)} className="cursor-pointer">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                      </svg>
                    </div>
                    <a href={`https://www.google.com/maps/place/${element.location[1]},${element.location[0]}`} target="_blank" rel="noreferrer">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                        <path fill-rule="evenodd" d="M15.75 2.25H21a.75.75 0 0 1 .75.75v5.25a.75.75 0 0 1-1.5 0V4.81L8.03 17.03a.75.75 0 0 1-1.06-1.06L19.19 3.75h-3.44a.75.75 0 0 1 0-1.5Zm-10.5 4.5a1.5 1.5 0 0 0-1.5 1.5v10.5a1.5 1.5 0 0 0 1.5 1.5h10.5a1.5 1.5 0 0 0 1.5-1.5V10.5a.75.75 0 0 1 1.5 0v8.25a3 3 0 0 1-3 3H5.25a3 3 0 0 1-3-3V8.25a3 3 0 0 1 3-3h8.25a.75.75 0 0 1 0 1.5H5.25Z" clip-rule="evenodd" />
                      </svg>
                    </a>
                  </div>
                </div>
              }
            </div>
          </>
        ))}
      </div>


      <div className="mt-3 bg-red-500 bg-opacity-70 w-auto px-2 rounded-t-lg py-1 shadow-lg font-bold text-gray-900 flex justify-between">
        <p className="truncate">
          {userActive.filter((element) => element.fencing === 0).length} user out Location
        </p>
        <div className="flex text-xs  rounded-lg px-2 gap-2 py-1">
          <div>
            <p className="bg-blue-200 rounded-lg pl-1 truncate">
              {totalAgency.guestOffline}
              <span className="bg-blue-500 rounded-lg px-1 text-gray-100">
                Guest
              </span>
            </p>
          </div>
          <div>
            <p className="bg-green-200 rounded-lg pl-1 truncate">
              {totalAgency.PGEOffline}
              <span className="bg-green-500 rounded-lg px-1 text-gray-100">
                PGE
              </span>
            </p>
          </div>

        </div>
      </div>

      <div className="pt-1 px-2 text-xs w-full h-56 max-h-56 overflow-y-scroll rounded-b-lg border-b-2 border-x-2 border-gray-200 bg-gray-200 bg-opacity-80 pb-3 shadow-lg">
        {userActive && userActive.length !== 0 && sortedOfflineUsers.map((element) => (
          <>
            <div
              className={`p-1 ${element.sos === 1 && "rounded-lg bg-orange-400 animate-pulse"} mb-1 hover:bg-gray-300`}>
              <div className="flex justify-between px-5">
                <div
                  className="cursor-pointer flex mt-2 justify-start gap-1  align-middle"
                  key={element}
                >
                  {element.fencing === 0
                    &&
                    <>
                      <div className={`w-2 h-2 rounded-full mt-1 ${element.status === 2 && "bg-gray-700"} ${element.status === 1 && "bg-green-700 animate-pulse"} ${element.status === 0 && "bg-red-700"}`} />
                      <p
                        onClick={() => handleGetLogDaily(element)}

                        className={`truncate font-bold ${element.agency !== "PGE" ? "bg-blue-200 px-1 rounded-lg" : "bg-green-200 px-1 bg-opacity-80 rounded-lg"}`}>
                        {element.name}</p>
                      {
                        element.timestamps !== undefined &&
                        <p className="text-gray-600 text-xs cursor-default">last seen {timeAgo.format(new Date(parseInt(element.timestamps)))}
                          {/* {
                            new Date(parseInt(element.timestamps))
                              .toLocaleTimeString(
                                "id-ID",
                                {
                                  hour: "numeric",
                                  minute: "numeric",
                                  second: "numeric",
                                  hour12: true, // atau false, tergantung apakah Anda ingin format 12 jam atau 24 jam
                                  timeZone: "Asia/Jakarta" // Tetapkan zona waktu ke WIB (UTC+7)
                                }
                              )} */}
                        </p>
                      }
                    </>
                  }
                </div>
                <div className="flex gap-1">
                  {element.message &&
                    // element.message.match(/Lt/i) && (
                    <div className="">
                      <span className="bg-gray-300 px-1 rounded-full font-medium">
                        {/* LT${element.message.split(/Lt/i)[1].split(" ")[0]} */}
                        {/* {element.message.match(/Lt/i) ? `${element.message}` : ''} */}
                        {element.message}
                      </span>
                    </div>
                    // )}
                  }
                </div>
              </div>
              {element.sos === 1 &&
                <div className="pl-8 pr-1 flex justify-between">
                  <div>

                    <span>
                      {`SOS active ! current location ${element.location[1]}, ${element.location[0]} on `}
                    </span>
                    <span>
                      {
                        new Date(parseInt(element.timestamps)).toLocaleDateString("id-ID", {
                          month: "long",
                          day: "numeric",
                          year: "numeric",
                        }) +
                        " at " +
                        new Date(parseInt(element.timestamps)).toLocaleTimeString(
                          "id-ID",
                          {
                            hour: "numeric",
                            minute: "numeric",
                            second: "numeric",
                            hour12: true, // atau false, tergantung apakah Anda ingin format 12 jam atau 24 jam
                          }
                        )}
                    </span>
                  </div>
                  <div className="flex gap-2 z-10">
                    <div onClick={() => handleSOS(element)} className="cursor-pointer">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                      </svg>
                    </div>
                    <a href={`https://www.google.com/maps/place/${element.location[1]},${element.location[0]}`} target="_blank" rel="noreferrer">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                        <path fill-rule="evenodd" d="M15.75 2.25H21a.75.75 0 0 1 .75.75v5.25a.75.75 0 0 1-1.5 0V4.81L8.03 17.03a.75.75 0 0 1-1.06-1.06L19.19 3.75h-3.44a.75.75 0 0 1 0-1.5Zm-10.5 4.5a1.5 1.5 0 0 0-1.5 1.5v10.5a1.5 1.5 0 0 0 1.5 1.5h10.5a1.5 1.5 0 0 0 1.5-1.5V10.5a.75.75 0 0 1 1.5 0v8.25a3 3 0 0 1-3 3H5.25a3 3 0 0 1-3-3V8.25a3 3 0 0 1 3-3h8.25a.75.75 0 0 1 0 1.5H5.25Z" clip-rule="evenodd" />
                      </svg>
                    </a>
                  </div>
                </div>
              }
            </div>
          </>
        ))}
      </div>
    </div>
  );
}



